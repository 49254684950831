<template>
  <div class="MediaOutletsListItem tag is-medium is-black">
    <span class="MediaOutletsListItem__title m-r-s">
      {{ outlet.name }}
    </span>

    <VButton
      :loading="isRemoving"
      class="is-white is-round is-tiny"
      @click.prevent="$emit('delete', outlet)"
    >
      <span class="icon">
        <VIcon type="times-thin" />
      </span>
    </VButton>
  </div>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'

export default {
  components: {
    VIcon,
    VButton
  },

  props: {
    outlet: {
      type: Object,
      required: true
    },
    isRemoving: {
      type: Boolean,
      default: false
    }
  }
}
</script>
